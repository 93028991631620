<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-flex xs2>
            <v-menu
              ref="menu1"
              v-model="beginDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedMonth"
                  :label="$t('global.month')"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" type="month" color="primary" @input="beginDateMenu = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr
              :class="{ 'selected-row': $helpers.get(table.selectedRow, 'id') === row.item.id }"
              style="cursor: pointer;"
              @click="onRowClick(row)"
            >
              <td>{{ row.item.name }}</td>
              <td>{{ row.item.category.name }}</td>
              <td>{{ row.item.fromDate | formattedDate }}</td>
              <td>{{ row.item.toDate | formattedDate }}</td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view @closed:detail="table.selectedRow = null" @detail-loaded:success="table.selectedRow = $event" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description Performance Component Logic
   * @date 28.07.2020
   */

  import { DISEASES_PAGINATE } from "../query";

  export default {
    name: "Disease",
    data: vm => ({
      beginDateMenu: false,
      date: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM"),
      table: {
        loading: false,
        data: [],
        criteria: {
          employee: vm.$store.state.auth.user.id,
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {},
        pagination: {
          page: 1,
          rowsPerPage: 10,
          sortBy: "name",
          descending: false
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        selectedRow: null,
        headers: [
          {
            text: vm.$t("hr.health.name"),
            align: "left",
            sortable: true,
            value: "name",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("hr.health.category"),
            align: "left",
            sortable: true,
            value: "category.name",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("hr.health.from"),
            align: "left",
            sortable: true,
            value: "fromDate",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("hr.health.to"),
            align: "left",
            sortable: true,
            value: "toDate",
            class: ["primary--text text-lg-left"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      },
      date: {
        handler(v) {
          this.table.criteria.beginDate = this.$moment(v)
            .startOf("month")
            .format("YYYY-MM-DD");
          this.table.criteria.endDate = this.$moment(v)
            .endOf("month")
            .format("YYYY-MM-DD");
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    computed: {
      formattedMonth() {
        return this.$moment(this.date).format("MMMM");
      }
    },
    methods: {
      onRowClick(row) {
        this.table.selectedRow = row.item;
        this.navigateToDetail();
      },
      navigateToDetail() {
        let routerParams = {
          id: this.$helpers.encodeID(this.table.selectedRow.id)
        };
        this.$router.push({ name: "health.detail", params: routerParams });
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: DISEASES_PAGINATE,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateDiseases.content || [];
              this.table.totalItems = data.filterWithPaginateDiseases.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
